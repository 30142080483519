import React, { useEffect, useState } from "react";
import { Form, Button, message, Radio, Typography, Space, Input, Spin } from "antd";
import {
  getContextSetting,
  updateContextSetting,
} from "../../services/userService";
import { createNotification } from "../../services/conversationService";
import '../../reducers/dashboardReducer';
import { useSelector } from "react-redux";

const { Title } = Typography;

const ContentSettingForm = ({ refreshNotifications }) => {
  const [form] = Form.useForm();
  const [prompts, setPrompts] = useState([]);
  const [selectedPromptId, setSelectedPromptId] = useState(null);
  const [loading, setLoading] = useState(false);
  const { darkMode } = useSelector(({dashboardReducer}) => dashboardReducer);

  useEffect(() => {
    fetchPrompts();
  }, []);

  const fetchPrompts = async () => {
    setLoading(true);
    try {
      const data = await getContextSetting();
      setPrompts(data);
      const activePrompt = data.find(p => p.isActive);
      if (activePrompt) {
        setSelectedPromptId(activePrompt.promptId);
        form.setFieldsValue({ contentOption: activePrompt.promptId });
      }
    } catch (error) {
      console.error("Error fetching prompts:", error);
      message.error("Failed to fetch prompts");
    } finally {
      setLoading(false);
    }
  };

  const onFinish = async () => {
    if (!selectedPromptId) {
      message.error("Please select a prompt");
      return;
    }

    setLoading(true);
    try {
      const selectedPrompt = prompts.find(p => p.promptId === selectedPromptId);
      await updateContextSetting(selectedPromptId, { 
        prompt: selectedPrompt.prompt,
        isActive: true  // Set the selected prompt as active
      });
      
      const notificationMessage = `Context Setting Updated to: ${selectedPrompt.prompt}`;
      await createNotification(notificationMessage);
      // refreshNotifications();

      message.success("Context Setting Updated successfully");
      await fetchPrompts(); // Reload prompts after update
    } catch (error) {
      console.error("Error updating prompt:", error);
      message.error("Failed to update prompt");
    } finally {
      setLoading(false);
    }
  };

  const handleRadioChange = (e) => {
    setSelectedPromptId(e.target.value);
  };

  const handlePromptChange = (promptId, value) => {
    setPrompts(prevPrompts =>
      prevPrompts.map(p =>
        p.promptId === promptId ? { ...p, prompt: value } : p
      )
    );
  };

  return (
    <Spin spinning={loading}>
      <div>
        <Title style={{ textAlign: "center", marginBottom: "20px", color: darkMode ? 'white' : '' }}> 
          Context Setting Form
        </Title>
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <Form.Item name="contentOption">
            <Radio.Group onChange={handleRadioChange} value={selectedPromptId}>
              <Space direction="vertical">
                {prompts.map(prompt => (
                  <Radio key={prompt.promptId} value={prompt.promptId}>
                    <Input.TextArea
                      value={prompt.prompt}
                      placeholder="Enter your custom prompt here"
                      autoSize={{ minRows: 3 }}
                      style={{ width: '450px' }}
                      onChange={(e) => handlePromptChange(prompt.promptId, e.target.value)}
                    />
                  </Radio>
                ))}
              </Space>
            </Radio.Group>
          </Form.Item>

          <Form.Item>
            <Space>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
              <Button htmlType="button" onClick={fetchPrompts}>
                Refresh
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </div>
    </Spin>
  );
};

export default ContentSettingForm;